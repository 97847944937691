<template>
  <div v-if="userHasPermission('view_bank_transactions')">
    <vx-card class="h-full order-planned-sales pt-4 pb-8" v-if="userHasPermission('view_bank_transactions')">

      <div class="flex items-center justify-between px-6">
        <div class="mr-2">

          <label>Zeitraum:</label>
          <mg-select v-model="requestedData.selectedPeriod" :options="periodOptions" name="period_options"
                     data-vv-as="Zeitraum"
                     placeholder="Zeitraum" track-by="name" label="description">
          </mg-select>
        </div>
        <div class="mr-2">
          <label>Einstellungen:</label>

          <vs-checkbox v-model="requestedData.MonthlyOrderSum">Monatliche Rechnungssumme</vs-checkbox>
          <vs-checkbox v-model="requestedData.MonthlyAccountingCommissionSum">Monatliche ausb. Provisionssumme (Umsatz)</vs-checkbox>
          <vs-checkbox v-model="requestedData.MonthlyContractSum">Monatliche Vertragsrechnungen</vs-checkbox>
          <vs-checkbox v-model="requestedData.MonthlyExpensesSum">Monatliche Ausgaben anhand erfassten Buchhaltungs-Dokumenten</vs-checkbox>
          <vs-checkbox v-model="requestedData.MonthlyBankTransactionsExpensesSum">Monatliche Ausgaben anhand Bank Transaktionen Abbuchungen (Steuern etc.) Brutto</vs-checkbox>
          <vs-checkbox v-model="requestedData.MonthlyPotentialOrderSum">Umsatzpotenzial nach Workload und Stundensatz (Mitarbeiter Einstellungen) (Min-Ziel)</vs-checkbox>
        </div>

        <div class="mr-2">
          <vs-checkbox v-model="requestedData.MonthlyWorkedHours">Monatlich gearbeitete Stunden</vs-checkbox>
          <vs-checkbox v-model="requestedData.SelledHours">Monatlich abgerechnete Stunden anhand ausb. Provisionsumsatz</vs-checkbox>
          <vs-checkbox v-model="requestedData.MonthlyRecordedPaymoHours">Monatliche erfasste Zeit in Paymo</vs-checkbox>
          <vs-checkbox v-model="requestedData.MonthlyUnpayableAndInternalRecordedPaymoHours">Monatliche unbezahlte und auf intern gebuchte Paymo Stunden </vs-checkbox>
          <vs-checkbox v-model="requestedData.MonthlyUnpayableAndInternalAndNotRecordedPaymoHours">Monatliche unbezahlte und auf intern gebuchte <b>sowie nicht gebuchte</b> Paymo Stunden </vs-checkbox>
        </div>
        <div class="mr-2 flex-">
          <vs-button color="primary" icon="refresh"  size="large" @click="fetchData">Daten laden</vs-button>
          <i v-if="inLoading"> Lädt noch</i>
        </div>
      </div>
    </vx-card>

    <div class="vx-row mb-base mt-4" v-if="userHasPermission('view_bank_transactions')">
      <div class="vx-col lg:w-1/2 w-full">

        <vs-alert color="danger" :active="true" icon="info" class="mb-4" v-show="!data.data">
          Keine Daten vorhanden, bitte Daten zuerst laden
        </vs-alert>

        <vx-card class="h-full" v-if="data.data">

          <h2 class="mt-4 mb-2">Controlling</h2>



          <h2 class="mt-4">Monatsverlauf</h2>
          <div id="chart">
            <vue-apex-charts type="area" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
          </div>
          <div class="chartSum mt-3">
            <label class="font-bold">Zahlen und Fakten</label>
            <span v-for="chartData in data.data" class="d-block">{{ chartData.name }}: {{
                parseCurrency(chartData)
              }}</span>


            <div class="mt-3"></div>
            <b>Krankheitstage</b> {{ data.otherData.sickdaysTotal }} Tag/e<br>
            <b>Durchschnittsstundensatz</b> {{ data.otherData.hourRateTotalService }}€ <br>
          </div>

          <div class="chartProducts mt-3">
            <label class="font-bold">Verkaufte Produkte/Leistungen</label>
            <vue-apex-charts type="bar" height="350" :options="productChartOptions" :series="productSeries"></vue-apex-charts>
          </div>

          <template lang="html" v-if="data.productStatistic">
            <div>
              <vs-table search :data="data.productStatistic.productList">
                <template slot="header">
                  <h3>
                    Produkte
                  </h3>
                </template>
                <template slot="thead">

                  <vs-th sort-key="name">
                    Produktname
                  </vs-th>
                  <vs-th sort-key="sum">
                    Summe
                  </vs-th>
                  <vs-th sort-key="typeId">
                    Typ
                  </vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td :data="data[indextr].name">
                      {{data[indextr].name}}
                    </vs-td>

                    <vs-td :data="data[indextr].sum">
                      {{data[indextr].sum|currency}}
                    </vs-td>

                    <vs-td :data="data[indextr].typeId">
                      {{data[indextr].typeId}}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </template>




        </vx-card>
      </div>
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card >
          <div >
            <label>Mitarbeiter :</label>
            <mg-select v-model="employee" :options="employeesOptions" name="employee" @select="selectEmployee"
                       data-vv-as="Mitarbeiter"
                       placeholder="Mitarbeiter" track-by="id" label="name">
            </mg-select>
            <i v-if="employeesControllingData.length == 0">Bitte auswählen um die Statistik zu laden</i>
            <i v-if="inLoadingEmployee"> Lädt noch</i>
          </div>

        </vx-card>

        <vx-card class="mt-4 employee-card" v-for="employeeControllingData in employeesControllingData">
          <div class="border-bottom">
            <h4>Mitarbeiter : {{employeeControllingData.userInfo.name}}</h4>
          </div>


          <h2 class="mt-4">Monatsverlauf</h2>
          <div class="employeeMonthlyChart">
            <vue-apex-charts type="area" height="350" :options="chartOptions" :series="employeeControllingData.series"></vue-apex-charts>
          </div>
          <div class="chartSum mt-3">
            <label class="font-bold">Zahlen und Fakten</label>
            <span v-for="chartData in employeeControllingData.controllingData.data" class="d-block">{{ chartData.name }}: {{
                parseCurrency(chartData)
              }}</span>


            <div class="mt-3"></div>
            <b>Krankheitstage</b> {{ employeeControllingData.controllingData.otherData.sickdaysTotal }} Tag/e  <br>
            <b>Durchschnittsstundensatz</b> {{ employeeControllingData.controllingData.otherData.hourRateTotalService }}€ <br>
          </div>

        </vx-card>
      </div>
    </div>


    <div v-if="!userHasPermission('view_bank_transactions')">
      Keine Berechtigung für diese Seite!
    </div>
  </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

import ApiService from "../../../api";
import moment from 'moment';
import QueryHelper from "../../../mixins/helper/query.helper";
import * as qs from "qs";
import PriceHelper from "../../../mixins/helper/price.helper";
import vSelect from 'vue-select'
import {mapGetters} from "vuex";
import GravatarImg from "vue-gravatar/src/components/GravatarImg";
import PlannedSalesModal from "../../../components/planned-sales/planned-sales-modal";
import PlannedSalesCalenderTable from "../../../components/planned-sales/planned-sales-calender-table";
import cloneDeep from "lodash/cloneDeep";
import VueApexCharts from 'vue-apexcharts'
import MgSelect from "../../../components/mg-select/MgSelect";

export default {
  components: {
    PlannedSalesModal,
    PlannedSalesCalenderTable,
    VueBootstrap4Table,
    'v-select': vSelect,
    GravatarImg,
    MgSelect,
    VueApexCharts
  },
  data() {
    return {
      // Edit/Create Modal
      selectedPlannedSalesId: null,
      plannedSalesModalActive: false,

      periodOptions: [
          {"name":"last_12months","description":"Letzte 12 Monate von heute"},
          {"name":"last_12months_from_last_month","description":"Letzte 12 Monate von letztem Monat"},
          {"name":"this_year","description":"Dieses Jahr"},
          {"name":"complete_last_year","description":"Vollständiges letztes Jahr (Jan-Dez)"},
          {"name":"last_3months","description":"Letzte 3 Monate"},
          {"name":"last_6months","description":"Letzte 6 Monate"},
          {"name":"last_1month","description":"Letzte Monat"},
          {"name":"last_24months","description":"Letzte 2 Jahre"},

      ],

      dataFetched: false,
      inLoading: false,
      inLoadingEmployee: false,
      data: [],
      employeesControllingData: [],
      offerData: [],
      requestedData: {
        MonthlyOrderSum: true,
        MonthlyAccountingCommissionSum: true,
        MonthlyContractSum: true,
        MonthlyExpensesSum: true,
        MonthlyBankTransactionsExpensesSum: true,
        MonthlyPotentialOrderSum: false,
        MonthlyWorkedHours: true,
        SelledHours: true,
        MonthlyRecordedPaymoHours: false,
        MonthlyUnpayableAndInternalRecordedPaymoHours: false,
        MonthlyUnpayableAndInternalAndNotRecordedPaymoHours: false,
        selectedPeriod: "last_12months"
      },
      employeesOptions: [],
      employee: [],

      /*
        Chart
         */
      series: [],
      chartOptions: {
        chart: {
          height: 250,
          type: 'area'
        },
        tooltip: {},
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        labels: ["2"],
        xaxis: {
          type: 'string',
          categories: [],

        },

      },

      /*
        Product Chart
         */
      productSeries: [{
        data: []
      }],
      productChartOptions: {
        chart: {
          height: 550,
          type: 'radar'
        },
        tooltip: {},
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        labels: ["2"],
        xaxis: {
          type: 'string',
          categories: [],

        },

      },
      employeeChartOptions: {
        chart: {
          height: 250,
          type: 'area'
        },
        tooltip: {},
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        labels: ["2"],
        xaxis: {
          type: 'string',
          categories: [],

        },

      },

      orderTypes: [],
    }
  },
  created() {
    this.fetchUsers()
  },
  computed: {
    ...mapGetters(['userHasPermission', 'userId', 'user']),
  },
  methods: {
    async fetchData(initial = false) {

      if (this.userHasPermission('view_bank_transactions')) {
        this.inLoading = true
        //  this.$vs.loading();
        //  this.$vs.loading.close()
        ApiService.get('controlling', {
          params: this.requestedData,
          paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
        }).then(response => {
          console.log(response.data.result)
          this.data = response.data.result;
          this.inLoading = false
          this.prepareChart();
          this.$vs.loading.close()
        })
      }

    },
    async fetchEmployeeData(employee) {

      if (this.userHasPermission('view_bank_transactions')) {
        const requestedData = JSON.parse(JSON.stringify(this.requestedData));
        //  this.$vs.loading();
        //  this.$vs.loading.close()
        requestedData["userId"] = employee.id
        ApiService.get('controlling', {
          params: requestedData,
          paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
        }).then(response => {

          this.employeesControllingData.push(
              {
                "userInfo": employee,
                "controllingData": response.data.result,
                "series": response.data.result.data
              }
          )
          this.setEmployeeChartOptions(response);
          this.inLoadingEmployee = false
          this.$vs.loading.close()
        })
      }

    },
    fetchUsers() {
      ApiService.get('users').then(response => {
        if (response.data.status !== 'success') {
          return;
        }

        this.employeesOptions = response.data.result;
      })
    },
    prepareChart() {
      if (!this.data) {
        return;
      }

      // Chart 1 - Monthly
      this.series = []
      this.chartOptions.labels = [];
      var obj = [];

      this.chartOptions.labels = this.data.labels
      this.series = this.data.data

      this.chartOptions = {
        labels: this.data.labels
      };

      // Chart 2 - Products
      this.productSeries = []
      this.productChartOptions.xaxis.categories = [];
      var obj = [];

      this.productChartOptions.xaxis.categories = this.data.productStatistic.categories
      this.productSeries = [{ data: this.data.productStatistic.data}]

      this.productChartOptions = {
        xaxis: {
          type: 'string',
          categories:  this.data.productStatistic.categories,

        }
      };
    },
    setEmployeeChartOptions(response) {
      if (!response) {
        return;
      }

      // Chart 1 - Monthly
      this.employeeChartOptions.labels = [];
      this.employeeChartOptions.labels = response.data.result.labels
      this.chartOptions = {
        labels: response.data.result.labels
      };
    },
    parseCurrency(value) {
      if (value.currency != undefined && value.currency == false) {
        return value.total
      }
      return PriceHelper.formatCurrency(value.total)
    },
    floatPointToCommaCurrency(value) {
      return PriceHelper.numberWithCommas(value)
    },
    employeesPromise() {
      return ApiService.get('users');
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.fetchData();
    },
    onChangePerPage(value) {
      this.config.per_page = value;
    },
    openCreateModal() {
      this.selectedPlannedSalesId = null;
      this.plannedSalesModalActive = true;
    },
    closePlannedSalesModal() {
      this.plannedSalesModalActive = false;
      this.fetchData();
      this.$refs.calender.fetchData()
    },
    formatDateTime(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    selectEmployee(employee){

      this.inLoadingEmployee = true
      this.fetchEmployeeData(employee);


    }
  }
}
</script>

<style lang="scss">
.border-bottom {
  border-bottom: 1px solid rgb(174, 177, 180);
}
.multiselect__content-wrapper{
  z-index: 500000000000;
  position: absolute;
}
</style>
